<template>
  <div>
    <div></div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast } from "vant";
export default {
  data() {
    return {
      // appId: "",
    };
  },
  created() {
    // 自定义加载图标
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      loadingType: "circular",
    });
    const queryParams = this.getQueryParams();
    console.log(queryParams, "参数"); // {param1: "value1", param2: "value2"}
    let appId = "";
    if (process.env.NODE_ENV !== "production") {
      appId = "TIDAhCTF"; //测试，开发环境
      // console.log("测试");
    } else {
      appId = "IDAxr3T9"; //生产环境
    }
    console.log(appId, "appid环境");
    if (queryParams.faceId && queryParams.firstMark === "1") {
      console.log("传参进来的", queryParams);
      let redirectType = 1;
      let version = "1.0.0";
      let url = encodeURIComponent("https://h5share.umfpay.com/#/faceIDAuth");
      let href = `https://${queryParams.optimalDomain}/api/web/willLogin?appId=${appId}&version=${version}&nonce=${queryParams.nonce}&orderNo=${queryParams.orderNo}&faceId=${queryParams.faceId}&url=${url}&from=${queryParams.from}&userId=${queryParams.userId}&sign=${queryParams.sign}&redirectType=${redirectType}`;

      console.log(href, 9999999);
      window.location.href = href;
    } else {
      setTimeout(() => {
        this.endEvent(queryParams);
      }, 100);
    }
  },
  mounted() {},
  methods: {
    //解析地址url参数
    getQueryParams() {
      let url = window.location.href;
      const queryStart = url.indexOf("?");
      if (queryStart === -1) {
        return {};
      }
      const queryStr = url.slice(queryStart + 1);
      const params = {};
      const pairs = queryStr.split("&");
      pairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
      });
      return params;
    },
    //退出或者返回
    endEvent(params) {
      const message = {
        orderNo: params.orderNo,
      };
      console.log(7879798, "退出", "返回小程序");
      wx.miniProgram.navigateBack({ delta: 1 });
      // ---------------------------------------------------
      // wx.miniProgram.postMessage({ data: message }, "*"); //带入数值
      // wx.miniProgram.navigateTo({
      //   url: `/pages/qbhome/index?param1=${value1}&param2=${value2}`,
      // });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.face-box {
  text-align: center;
  width: 100%;
  min-height: 100vh;
}

#videoParent {
  width: 320px;
  height: 320px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  margin-top: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
#tips {
  text-align: center;
  margin-top: 20px;
}

.imgBox {
  margin: 157px 210px 12px 210px;
  width: 330px;
  height: 330px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.tip {
  color: #333333;
  font-size: 28px;
  text-align: center;
}
.submit-btn {
  width: 620px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: #ef3535;
  border-radius: 45px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
  margin: 159px 65px 0 65px;
}
</style>
